import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

const Landing = (props) => (
    <Layout>
        <Helmet>
            <title>Conferences - Kimberly Plomp, PhD</title>
            <meta name="description" content="List of Conferences" />
            <meta name="keywords" content="conferences,presentations,podium,poster,sessions" />
        </Helmet>

        <BannerLanding title="Conferences" subtitle="Past and upcoming presentations" />

        <div id="main">
            <section id="one" className="spotlights">
            <section>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Podium Presentations</h3>
                            </header>
                            <p><i>*1<sup>st</sup> author unless names provided.</i></p>
                            <div className="table-wrapper">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>2021</td>
                                            <td>International Symposium on Biomedical Imaging<br/><i>Inter and intra variability of landmark positioning for vertebrae morphological characterization.</i><br/>F. Tomi, M. Evin, Y. Godio-Raboutet, K. Chaumoitre, <b>K. Plomp,</b> R. La Greca, A. Evin</td>
                                        </tr>
                                        <tr>
                                            <td>2020</td>
                                            <td>Royal Anthropological Institute<br/>Anthropology and Geography: Dialogues Past, Present and Future.<br/><i>Hominin occupation at Kilombe: a case study of habitability within the Eastern African Rift System.</i><br/>L. Timbrell, <b>K. Plomp,</b> M. Grove</td>
                                        </tr>
                                        <tr>
                                            <td>2020</td>
                                            <td>European Society of Human Evolution<br/><i>Incorporating structure into demographic models of the deep past: a case study from Late-Middle Pleistocene Africa.</i><br/>L. Timbrell, <b>K. Plomp,</b> M. Grove</td>
                                        </tr>
                                        <tr>
                                            <td>2019</td>
                                            <td>Society for Evolution, Medicine and Public Health<br/><i>Back Pain and the Evolution of Bipedalism</i></td>
                                        </tr>
                                        <tr>
                                            <td>2017</td>
                                            <td>7<sup>th</sup> International Council for Archaeozoology meeting of the Archaeozoology, Genetics, and Morphometrics working group<br/><i>Ancestral Aches? 3D Vertebral Morphology, Locomotion, and Human Spinal Health</i></td>
                                        </tr>
                                        <tr>
                                            <td>2016</td>
                                            <td>University of Copenhagen, Department of Forensic Medicine<br/><i>Ancestral Aches? 3D Vertebral Morphology, Locomotion, and Human Spinal Health</i></td>
                                        </tr>
                                        <tr>
                                            <td>2015</td>
                                            <td>SFU EcoEvo Retreat<br/><i>Ancestral Aches? 3D Vertebral Morphology, Locomotion, and Human Spinal Health</i></td>
                                        </tr>
                                        <tr>
                                            <td>2015</td>
                                            <td>SFU Archaeology Day Symposium<br/><i>Ancestral Aches? Vertebral Morphology, Locomotion, and Human Spinal Health</i></td>
                                        </tr>
                                        <tr>
                                            <td>2014</td>
                                            <td>Canadian Association of Physical Anthropology<br/><i>Ancestral Aches? Vertebral Morphology, Locomotion, and Human Spinal Health</i></td>
                                        </tr>
                                        <tr>
                                            <td>2014</td>
                                            <td>European Society for the study of Human Evolution<br/><i>Ancestral Aches? Vertebral Morphology, Locomotion, and Human Spinal Health</i></td>
                                        </tr>
                                        <tr>
                                            <td>2012</td>
                                            <td>Palaeopathology Association European Meeting<br/><i>Vertebral Morphology as an Aetiological factor of Schmorl's Nodes in the Lumbar Spine</i></td>
                                        </tr>
                                        <tr>
                                            <td>2011</td>
                                            <td>Canadian Association for Physical Anthropology<br/><i>2D Shape Analysis of the Twelfth Thoracic Vertebra with Schmorl's Nodes</i></td>
                                        </tr>
                                        <tr>
                                            <td>2011</td>
                                            <td>British Association of Biological Anthropology and Osteoarchaeology<br/><i>2D Shape Analysis of the Twelfth Thoracic Vertebra with Schmorl's Nodes</i></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Poster Presentations</h3>
                            </header>
                            <div className="table-wrapper">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>2019</td>
                                            <td>European Society for the study of Human Evolution<br/><i>Back Pain and Bipedalism</i></td>
                                        </tr>
                                        <tr>
                                            <td>2019</td>
                                            <td>Unravelling Human Origins Conference, University of York<br/><i>Investigating the strength of temporal bone shape variation for distinguishing between closely-related populations using 3D geometric morphometrics.</i></td>
                                        </tr>
                                        <tr>
                                            <td>2018</td>
                                            <td>British Association of Biological Anthropology and Osteoarchaeology<br/><i>Investigating the strength of temporal bone shape variation for distinguishing between closely-related populations using 3D geometric morphometrics</i></td>
                                        </tr>
                                        <tr>
                                            <td>2018</td>
                                            <td>European Society for the study of Human Evolution<br/><i>Potential functional adaptations for bipedalism in human vertebrae: A 3D geometric morphometric analysis</i></td>
                                        </tr>
                                        <tr>
                                            <td>2017</td>
                                            <td>British Association of Biological Anthropology and Osteoarchaeology<br/><i>The Ancestral Shape Hypothesis: 3D Vertebral Morphology, Locomotion, and Human Spinal Health</i></td>
                                        </tr>
                                        <tr>
                                            <td>2017</td>
                                            <td>Paleoanthropology Society<br/><i>Bipedalism Evolved from Knuckle-Walking: Evidence from 3D Geometric Morphometric Analyses of Thoracic and Lumbar Vertebral Shape of Homo sapiens, Pan troglodytes, Pongo pygmaeus, and Papio hamadryas</i></td>
                                        </tr>
                                        <tr>
                                            <td>2012</td>
                                            <td>Palaeopathology Association European Meeting<br/><i>Quantifying Rhinomaxillary Syndrome</i></td>
                                        </tr>
                                        <tr>
                                            <td>2010</td>
                                            <td>British Association of Biological Anthropology and Osteoarchaeology.<br/><i>Using Geometric Morphometrics to Quantify Palaeopathology: Osteoarthritis</i></td>
                                        </tr>
                                        <tr>
                                            <td>2010</td>
                                            <td>Palaeopathology Association European Meeting<br/><i>Using Geometric Morphometrics to Quantify Palaeopathology: Osteoarthritis</i></td>
                                        </tr>
                                        <tr>
                                            <td>2010</td>
                                            <td>Palaeopathology Association American Meeting<br/><i>The Occurrence of Costovertebral Osteoarthritis in Two Medieval English Populations</i></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Sessions Chaired</h3>
                            </header>
                            <div className="table-wrapper">
                                <table>
                                    <tbody>
                                    <tr>
                                            <td>2021</td>
                                            <td>7<sup>th</sup> International Society for Evolution, Medicine and Public Health Annual Meeting - Symposium: Evolving Health: Palaeopathology and Evolutionary Medicine</td>
                                        </tr>
                                        <tr>
                                            <td>2019</td>
                                            <td>5<sup>th</sup> International Society for Evolution, Medicine and Public Health Annual Meeting - Symposium: Evolving Health: Palaeopathology and Evolutionary Medicine</td>
                                        </tr>
                                        <tr>
                                            <td>2017</td>
                                            <td>7<sup>th</sup> International Council for Archaeozoology meeting of the Archaeozoology, Genetics, and Morphometrics working group</td>
                                        </tr>
                                        <tr>
                                            <td>2014</td>
                                            <td>Canadian Association of Physical Anthropology Annual Meeting - Human Evolution and Genomics Session</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Conference Committee</h3>
                            </header>
                            <div className="table-wrapper">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>2019</td>
                                            <td>GMM Symposium and Workshop – HumAnE Centre for Bioarchaeology, University of Exeter</td>
                                        </tr>
                                        <tr>
                                            <td>2017</td>
                                            <td>7<sup>th</sup> International Council for Archaeozoology meeting of the Archaeozoology, Genetics, and Morphometrics working group</td>
                                        </tr>
                                        <tr>
                                            <td>2015</td>
                                            <td>Human Evolutionary Studies Program Annual Symposium, Simon Fraser University</td>
                                        </tr>
                                        <tr>
                                            <td>2012</td>
                                            <td>Durham University Postgraduate Bioarchaeology Workshop</td>
                                        </tr>
                                        <tr>
                                            <td>2011</td>
                                            <td>Durham University Anthropology Postgraduate Conference</td>
                                        </tr>
                                        <tr>
                                            <td>2010</td>
                                            <td>Moving Goalposts: Innovations and Developments in the Study of Human Remains</td>
                                        </tr>
                                        <tr>
                                            <td>2009</td>
                                            <td>British Association of Biological Anthropology and Osteoarchaeology</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </div>

    </Layout>
)

export default Landing
